import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { verdeBackground, verdeIcon, verdeText } from '../../../themes/variants/TucarColors';

function sleep(ms = 30000) {
  const myPromise = new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
  return myPromise;
}

const SwitchIcon = ({ loading, onOpen, onClose, keyOn, keyOff, resize }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleOpen = async () => {
    try {
      setWaiting(true);
      await keyOn();
      await sleep(1000);
      await onOpen();
      await sleep();
      setErrorMessage('');
      setWaiting(false);
    } catch (error) {
      setWaiting(false);
      setErrorMessage(
        'Ha ocurrido un error al abrir el vehículo. Revisa el vehículo e intenta nuevamente.',
      );
    }
  };

  const handleClose = async () => {
    try {
      setWaiting(true);
      await keyOff();
      await sleep(1000);
      await onClose();
      await sleep();
      setErrorMessage('');
      setWaiting(false);
    } catch (error) {
      setWaiting(false);
      setErrorMessage(
        'Ha ocurrido un error al cerrar el vehículo. Revisa el vehículo e intenta nuevamente.',
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          opacity: loading || waiting ? '60%' : '100%',
          display: 'flex',
          gap: '20%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          disabled={loading || waiting}
          onClick={handleOpen}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Typography
            sx={{
              lineHeight: 1,
              color: verdeText,
              textAlign: 'center',
              fontFamily: 'Poppins-Regular',
            }}
          >
            Abrir auto
          </Typography>
          <IconButton
            variant="contained"
            disabled
            sx={{
              padding: '5px',
              height: '100%',
              backgroundColor: verdeBackground,
              borderRadius: '50%',
              boxShadow: 0,
              '&:disabled': {
                backgroundColor: verdeBackground,
              },
            }}
          >
            <LockOpenIcon
              sx={{
                color: verdeText,
                borderRadius: '25px',
                width: resize ? '25px' : '30px',
                height: resize ? '25px' : '30px',
                padding: '2px',
              }}
            />
          </IconButton>
        </Box>

        <Box
          disabled={loading || waiting}
          onClick={handleClose}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Typography
            sx={{
              lineHeight: 1,
              color: verdeIcon,
              textAlign: 'center',
              fontFamily: 'Poppins-Regular',
            }}
          >
            Cerrar Auto
          </Typography>

          <IconButton
            variant="contained"
            disabled
            sx={{
              padding: '5px',
              height: '100%',
              backgroundColor: verdeBackground,
              borderRadius: '50%',
              boxShadow: 0,
              '&:disabled': {
                backgroundColor: verdeBackground,
              },
            }}
          >
            <LockIcon
              sx={{
                color: verdeIcon,
                backgroundColor: verdeBackground,
                borderRadius: '25px',
                width: resize ? '25px' : '30px',
                height: resize ? '25px' : '30px',
                padding: '2px',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      {errorMessage !== '' && (
        <Typography
          sx={{
            display: 'auto',
            fontFamily: 'Poppins-regular',
            fontSize: '13px',
            color: 'red',
            textAlign: 'center',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

SwitchIcon.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  resize: PropTypes.bool,
  loading: PropTypes.bool,
  keyOn: PropTypes.func,
  keyOff: PropTypes.func,
};

SwitchIcon.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  keyOn: () => {},
  keyOff: () => {},
  resize: false,
  loading: false,
};

export default SwitchIcon;
