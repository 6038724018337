import { TucarTheme } from '@tucar/pit-stop';

export const backgroundStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '30px',
  flex: 1,
  paddingInline: '20px',
  minHeight: '100vh',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    height: '520px',
    width: '520px',
    background: TucarTheme.colors.blue500,
    borderRadius: '520px',
    top: '-244px',
    left: '83px',
    opacity: '0.3',
    filter: 'blur(100px)',
    zIndex: '0',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '520px',
    width: '520px',
    background: TucarTheme.colors.blue100,
    borderRadius: '520px',
    top: '-42px',
    left: '-245px',
    opacity: '0.2',
    filter: 'blur(100px)',
    zIndex: '0',
  },
};

export const goalsImageStyle = {
  zIndex: 3,
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
