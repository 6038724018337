import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box as MuiBox } from '@mui/material';
import { Button, TucarTheme, Typography } from '@tucar/pit-stop';

import { useAuthParams } from '../../hooks';
import { getAuthorizationToken, getTokenMetadata } from '../../api/auth';
import {
  getRandomState,
  setAuth,
  isAuthenticated,
  initAuthentication,
  logout,
} from '../../graphql/store';

import LoadingScreen from '../../components/LoadingScreen';
import TucarLogo from '../../../public/images/logotipo.svg';
import GoalsImg from '../../../public/images/Isotipo.svg';
import { backgroundStyle, goalsImageStyle } from './styles';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { authUrl } = useAuthParams();

  const handleSuccessRequest = ({ data }) => {
    setAuth(data);
  };

  const handleMetadataSuccess = ({ data }) => {
    const { user_id: userId } = data || {};
    initAuthentication({ userId });
    setLoading(false);
    navigate('/app/v2/dashboard', { replace: true });
  };

  const handleError = () => {
    setLoading(false);
    logout({ hardLogout: true });
  };

  const getAuthToken = async () => {
    const code = searchParams.get('code');
    const { data: authData } = await getAuthorizationToken(
      { code },
      {
        onSuccess: handleSuccessRequest,
        onError: handleError,
      },
    );
    const { access_token: accessToken } = authData || { accessToken: '' };
    await getTokenMetadata(accessToken, {
      onSuccess: handleMetadataSuccess,
      onError: handleError,
    });
  };

  const handleSignIn = () => {
    window.location.href = authUrl;
  };

  useEffect(() => {
    const authenticated = isAuthenticated();
    if (
      !authenticated &&
      searchParams.get('code') &&
      searchParams.get('state') === getRandomState()
    ) {
      setLoading(true);
      getAuthToken();
    } else if (authenticated) {
      navigate('/app/v2/dashboard', { replace: true });
    }
  }, []);

  if (loading) return <LoadingScreen fullScreen size="big" />;

  return (
    <MuiBox sx={backgroundStyle}>
      <MuiBox
        sx={{
          position: 'absolute',
          top: '40px',
          left: '20px',
          height: '22px',
          width: '76px',
          zIndex: 2,
        }}
      >
        <img src={TucarLogo} alt="logo" />
      </MuiBox>

      <MuiBox sx={goalsImageStyle}>
        <img src={GoalsImg} alt="goals-welcome" width="50%" height="50%" />
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '19px',
          paddingBottom: '10px',
        }}
      >
        <Typography
          align="center"
          variant="p"
          weight="medium"
          style={{ lineHeight: 1.2, color: TucarTheme.colors.gray700, fontSize: '14px' }}
        >
          Arrienda un auto y genera ganancias usando aplicaciones. Comienza aquí 👇🏻
        </Typography>
      </MuiBox>

      <MuiBox sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
        <Button
          size="large"
          width="max-content"
          onClick={handleSignIn}
          contentStyle={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          Iniciar
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default SignIn;
