import { gql } from '@apollo/client';

const EXECUTE_COMMAND = {
  mutation: gql`
    mutation ExecuteCommand($carId: String!, $command: String!) {
      executeCommand(carId: $carId, command: $command) {
        carId
        message
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default EXECUTE_COMMAND;
