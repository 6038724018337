import React, { useEffect, useState } from 'react';
import { useReactiveVar, useMutation, useQuery } from '@apollo/client';

import { Box, useMediaQuery, Typography, Button } from '@mui/material';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import SwitchIcon from '../switchIcon';
import UNLOCK_CAR from '../../../graphql/mutations/car/unlockCar';
import LOCK_CAR from '../../../graphql/mutations/car/lockCar';
import GET_CAR_CONNECTION from '../../../graphql/querys/car/getCarConnection';
import LoadingProgress from '../../ui/LoadingProgress';
import GET_ADVICE_COUPLES from '../../../graphql/querys/exchange/getAdviceAssigned';
import EXECUTE_COMMAND from '../../../graphql/mutations/car/executeCommand';

const LockUnlockCarButton = () => {
  const matches = useMediaQuery('(max-width:300px)');
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [connected, setConnected] = useState(false);
  const { rent } = useReactiveVar(getDriverStore);
  const [showButton, setShowButton] = useState(false);
  const [unlockCar, { loading: loadingUnlock }] = useMutation(UNLOCK_CAR.mutation, {
    ...UNLOCK_CAR.policies,
    skip: !rent?.carId || !connected,
    variables: { carId: rent?.carId },
  });
  const [keyOn, { loading: loadingKeyOn }] = useMutation(EXECUTE_COMMAND.mutation, {
    ...EXECUTE_COMMAND.policies,
    skip: !rent?.carId || !connected,
    variables: { carId: rent?.carId, command: 'key_on' },
  });
  const [keyOff, { loading: loadingKeyOff }] = useMutation(EXECUTE_COMMAND.mutation, {
    ...EXECUTE_COMMAND.policies,
    skip: !rent?.carId || !connected,
    variables: { carId: rent?.carId, command: 'key_off' },
  });
  const [lockCar, { loading: loadingLock }] = useMutation(LOCK_CAR.mutation, {
    ...LOCK_CAR.policies,
    skip: !rent?.carId || !connected,
    variables: { carId: rent?.carId },
  });
  const { data: coupleData, loading: coupleLoading } = useQuery(GET_ADVICE_COUPLES.query, {
    ...GET_ADVICE_COUPLES.policies,
    variables: {
      labelId: 'REMOTE_CONTROL_DOORS',
      driverId: uid,
      limit: 1,
      offset: 0,
    },
  });
  const { data, loading, refetch } = useQuery(GET_CAR_CONNECTION.query, {
    ...GET_CAR_CONNECTION.policies,
    skip: !rent?.carId,
    variables: {
      carId: rent?.carId,
    },
  });

  useEffect(() => {
    if (!loading && data?.getCarBasicTelemetry) {
      setConnected(data.getCarBasicTelemetry?.connected || false);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!coupleLoading && coupleData?.getCouples?.instances?.length && coupleData?.getLabel) {
      const { elements: carList } = coupleData?.getLabel || { elements: [] };
      const { drivers: driversInfo, carId } = coupleData.getCouples.instances[0] || {
        drivers: [],
        carId: '',
      };
      if (driversInfo.length === 1) {
        setShowButton(true && carList.includes(carId));
      } else {
        setShowButton(!!rent?.carId && carList.includes(rent?.carId));
      }
    }
  }, [coupleData, coupleLoading]);

  if (coupleLoading || !showButton) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '15px' }}>
      {!loading ? (
        <Button
          onClick={() => refetch()}
          variant="outlined"
          size="small"
          sx={{ width: 'min-content', alignSelf: 'center' }}
        >
          Recargar
        </Button>
      ) : (
        <LoadingProgress allScreen={false} />
      )}

      <SwitchIcon
        loading={loadingLock || loadingUnlock || loadingKeyOn || loadingKeyOff}
        onOpen={unlockCar}
        keyOn={keyOn}
        keyOff={keyOff}
        onClose={lockCar}
        resize={matches}
      />

      {!loading && (
        <Typography
          sx={{
            display: 'auto',
            fontFamily: 'Poppins-regular',
            fontSize: '13px',
            color: 'grey',
            textAlign: 'center',
          }}
        >
          {!connected ? 'vehículo desconectado' : 'vehículo conectado'}
        </Typography>
      )}
    </Box>
  );
};
export default LockUnlockCarButton;
