import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { formatterCLP } from '../../../helpers/formatters';

const LiquidationsBox = ({ content, onClick }) => {
  const { positive, amount, date, status } = content;
  const paid = status === 'Pagado';
  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: '14px',
        display: 'flex',
        background: '#FFF',
        width: '100%',
        padding: '26px 20px',
        flexDirection: 'column',
        gap: '12px',
        boxShadow:
          '4px 4px 20px 0px rgba(111, 140, 176, 0.41), 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
      }}
    >
      <Box
        sx={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', width: '100%', gap: '10px' }}
      >
        <IconButton
          sx={{
            width: '50px',
            height: '50px',
            boxShadow:
              '4px 4px 14px 0px #D9D9D9 inset, -4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset',
          }}
        >
          {paid || positive ? (
            <MonetizationOnIcon sx={{ fontSize: '30px', color: '#7FB418' }} />
          ) : (
            <MoneyOffIcon sx={{ fontSize: '30px', color: '#FF2300' }} />
          )}
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins-Medium',
              fontSize: '16px',
              color: paid || positive ? '#7FB418' : '#FF2300',
            }}
          >
            {paid ? 'Pagado' : `${status === 'Pendiente' ? 'Pendiente de pago' : 'No pagado'}`}
          </Typography>
          <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', color: '#5b5d71' }}>
            {date}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins-Light',
              fontSize: '13px',
              color: '#5B5D71',
              lineHeight: 1.2,
            }}
          >
            {positive
              ? `${
                  status === 'Pagado'
                    ? 'Depositado en tu cuenta.'
                    : 'El pago se depositará a tu cuenta.'
                }`
              : `${
                  status === 'Vencida'
                    ? 'El pago ha vencido. Se cargará en tu próxima liquidación como deuda.'
                    : 'Deuda pendiente de pago. Debe realizar el pago el día de hoy en la sección de pagos.'
                }`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-evenly',
          display: 'flex',
          padding: '12px 26px',
          backgroundColor: '#E3EDF7',
          borderRadius: '10px',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', color: '#5B5D71' }}>
          Monto {positive ? 'abonado' : 'adeudado'}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins-Medium',
            fontSize: '14px',
            color: positive ? '#7FB418' : '#FF2300',
          }}
        >
          {positive ? '+' : '-'}
          {formatterCLP.format(amount)}
        </Typography>
      </Box>
    </Box>
  );
};

LiquidationsBox.propTypes = {
  content: PropTypes.shape({
    positive: PropTypes.bool,
    amount: PropTypes.number,
    date: PropTypes.string,
    status: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

LiquidationsBox.defaultProps = {
  content: {
    positive: false,
    amount: 0,
    date: '',
    status: '',
  },
  onClick: () => {},
};

export default LiquidationsBox;
